.security-params {
  display: flex;
}

.security-param {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;

  &--readonly {
    font-weight: 600;
  }
}

.param-label {
  font-weight: 600;
  margin-bottom: 5px;
}