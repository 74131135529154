@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  .progress-bar-bar {
    background-color: $dark-blue-grey;
    height: 24px;
  }
}

.progress-bar-labels {
  display: flex;
  width: 100%;
}

.progress-bar-label {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-right: 1px solid $very-light-gray;
  &:last-child {
    border-right: none;
  }
}

@include media-tiny {
  .progress-bar,
  .progress-bar-bar {
    border-radius: 0;
  }

  .progress-bar-labels {
    display: none;
  }
}
