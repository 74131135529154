@import "../../../../variables.scss";

.label {
  display: flex;
  margin-bottom: 8px;
  margin-left: 15px;
  font-weight: 600;
  font-size: 14px;
  color: $dark-blue-grey;

  &--no-left-margin {
    margin-left: 0;
  }
}