@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  top: 0;
  bottom: 0;
  background-color: $white;
  border: solid 1px $very-light-gray;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &--with-footer.root {
    @include media-min-small {
      padding-bottom: 0;
    }
    @include media-tiny {
      padding-bottom: 0;
    }
  }

  @include media-min-small {
    width: 768px;
    padding: 86px 23px 23px 42px;
  }
  @include media-tiny {
    width: 100%;
    padding: 32px 4px 23px 10px;
  }
}

.back-link {
  position: absolute;
  padding-left: 0;
  top: 48px;
  text-decoration: none;
}

.close {
  position: absolute;
  color: $gemini-gray;

  svg {
    font-size: 16px;
  }

  @include media-min-small {
    right: 47px;
    top: 48px;
  }
  @include media-tiny {
    right: 16px;
    top: 28px;
  }
}

.title {
  margin-bottom: 9px;
  margin-right: 50px;
}

.description {
  margin-bottom: 20px;
}

.side-panel {
  position: fixed;
  z-index: $left-panel-z-index + 1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
