@import "../../../mixins.scss";

@mixin margins($left, $right) {
  margin: {
    left: -$left;
    right: -$right;
  }
  padding: 0 $right 0 $left;
}

.root {
  display: flex;
  flex-direction: column;
  max-height: 87px;
  height: 87px;
  @include margins(43px, 23px);
  
  &--sticky {
    justify-content: center;
    z-index: 1;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    background-color: $white;
    border-top: 1px solid rgba($color: $gemini-gray, $alpha: 0.5);

    .error {
      position: absolute;
      right: 20px;
      bottom: 3px;
      margin: 0;
    }
  }

  .save-button {
    margin-left: 28px;
  }

  @include media-tiny {
    @include margins(10px, 4px);
  }
}

.content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 100%;

  @include media-tiny {
    justify-content: space-between;
  }
}

.error {
  display: flex;
  justify-content: flex-end;
  color: $dark-orange;
  margin-top: -20px;
  margin-bottom: 5px;
}
