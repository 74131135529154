@import "../../../variables.scss";
@import "../../../mixins.scss";

.add-container {
  min-height: calc(100% - 85px);
  display: flex;
  flex-direction: column;

  @include media-tiny {
    min-height: calc(100% - 105px);
  }
}

.error-button {
  font: inherit;
  color: inherit;
}

.storage-pool-link {
  font-size: 14px;
}

.progress {
  margin-bottom: 45px;

  @include media-min-small {
    margin-top: 10px;
  }
  @include media-tiny {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.question {
  margin-bottom: 12px;
  font-weight: 600;
}

.sub-section {
  margin-left: 40px;
  margin-top: 20px;

  @include media-tiny {
    margin-left: 10px;

    [class*="input-with-units_input"] {
      margin-bottom: 10px;
    }
  }
}

.input {
  &--default {
    input {
      width: 200px;
    }
  }
}
