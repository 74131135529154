@import "../../../variables.scss";

.root {
  display: inline-block;
  width: 60px;
}

.button {
  background: none;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  padding: 5px 0;
  width: 100%;
  height: 100%;
}

.ball {
  $size: 8px;
  background-color: $bluish-grey;
  width: $size;
  height: $size;
  border-radius: 20px;
  margin-right: 4px;
}
