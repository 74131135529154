@import "../../../../variables.scss";


.textarea {
  border: 1px solid $gemini-gray;
  border-radius: 25px;
  outline: none;
  padding: 9px 20px;
  width: 100%;
  font: inherit;
  color: inherit;
  font-size: 16px;
  resize: none;

  &::placeholder {
    color: $pesto-gray;
  }

  &:disabled {
    color: $pesto-gray;
    background-color: $white;
  }
}