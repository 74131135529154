@import "../../../variables.scss";
@import "../../../mixins.scss";

:global(.MuiTooltip-tooltip).root {
  background-color: $dark-blue-grey;
  font-size: 14px;
  padding: 15px;
  margin: 20px 0 20px -18px;
  padding-left: 19px;
  border-radius: 10px;
}

:global(.MuiTooltip-arrow).arrow {
  $margin: 15px;

  color: $dark-blue-grey;
  font-size: 16px;
  margin-left: 10px;

  .popper[x-placement*="start"] & {
    margin-left: $margin;
  }
  .popper[x-placement*="end"] & {
    margin-right: $margin;
  }
}
