.email-sent-header {
  margin: 37px 42px;
} 

.sent-files {
  display: flex;
  font-weight: 600;
  margin-left: 88px;
}

.sent-files-link {
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
}