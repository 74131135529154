@import "../../../../../../variables.scss";

.root {
  flex: 1;
}

.no-files {
  margin: 32px;
  border: 1px dotted $bluish-grey;
  background-color: $alabaster;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 64px);
  padding: 20px;
}

.drag-files-here {
  font-size: 22px;
  font-weight: 600;
  margin: 6px 0 12px 0;
}

.files {
  padding: 65px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 12px 20px;
  border-bottom: 1px solid $very-light-gray;
}

.files-count {
  display: flex;
  align-items: center;
}

.files-count-chip {
  background-color: $gemini-gray;
  border: 1px solid $gemini-gray;
  border-radius: 25px;
  display: inline-block;
  color: $white;
  font-weight: 600;
  padding: 5px 10px;
  margin-right: 9px;
}

.list {
  padding: 0 20px;
}

.list-item {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.delete-file-icon {
  font-size: 16px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-file-button {
  color: $bluish-grey;
  text-decoration: underline;
  cursor: pointer;
  input {
    display: none;
  }
}

.file-name {
  margin-left: 5px;
}

.footer {
  margin: 15px 0 0 45px;
}