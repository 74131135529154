$button-group-width: 572px;

.pending-header {
  max-width: $button-group-width;
  text-align: left;
  margin-left: 5px;
}

.button-groups {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 40px;
  border-radius: 50px;
  font-weight: normal;
  min-width: $button-group-width;
  background-color: #2b3349;
}

.mul-button {
  font-size: 16px;
  font-weight: normal;
  background: transparent;
  border: 0px;
  color: #777777;
  padding: 12px 17px;
  border-radius: 50px;
  white-space: nowrap;

  &--active {
    background: #6c969d;
    color: #fff;
  }
}

.btn {
  cursor: pointer;
  font-family: inherit;

  &.focus,
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  &--next {
    width: 213px;
    font-size: 24px;
    background: #6c969d;
    color: #fff;
    border: 0px;
    padding: 9px 0px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    text-decoration: none;
    border-radius: 50px;
    font-weight: normal;
  }
}

.example-image {
  display: flex;
  flex-wrap: wrap;
}

.status-bar-image {
  margin-right: 44px;
}

.example-image p,
.ex-text p {
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
}

.ex-text {
  h4 {
    color: inherit;
    font-size: 24px;
    font-weight: 500;
  }
  p {
    margin-top: 3px;
  }
}

.item-ex {
  background: rgba(216, 216, 216, 0.2);
  display: flex;
  margin-left: 29px;
  border-radius: 8px;
  width: 238px;
  height: 76px;
  padding: 17px;
  padding-right: 25px;
  align-items: center;
  margin-bottom: 16px;

  &__text {
    font-size: 16px;
    text-align: left;
    margin-left: 12px;
  }
}
