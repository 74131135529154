@import "../../../variables.scss";

$border-radius: 20px;

.root {
  border-radius: $border-radius;
  background-color: $very-light-gray;
}

.bar {
  height: 100%;
  border-radius: $border-radius;
  background-color: currentColor;
}
