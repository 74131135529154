@import "../../../variables.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  z-index: 1320; //to be over MuiBackdrop(loading indicator for dashboard widget)
}

.popup {
  background-color: $white;
  border: solid 1px $very-light-gray;
  display: grid;
  z-index: 1320; ////to be over MuiBackdrop(loading indicator for dashboard widget)
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.menu-items {
  display: grid;
}

.item {
  font: inherit;
  cursor: pointer;
  padding: 7px 19px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  outline: none;
  
  &:focus {
    background-color: $gallery;
  }

  &:hover {
    color: $white;
    background-color: $bluish-grey;
    transition: background-color 150ms;
  }
}
