@import "../../../../mixins.scss";

.root {
  display: inline-block;
}

.input {
  margin-right: 19px;

  @include media-tiny {
    margin-right: 3px;
  }
}
