@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  margin-bottom: 35px !important;

  @include media-min-small {
    margin-left: 23px;
  }
}

.content {
  @include media-min-small {
    margin-left: 27px;
  }
}
