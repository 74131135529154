@import "../../../../variables.scss";

.table {
  display: inline-flex;

  .node-link,
  .col-name,
  .col-provider {
    text-align: left;
  }
  
  .col-manage,
  .col-content,
  .col-metadata,
  .col-key {
    text-align: center;
  }
  
  .col-key,
  .col-content,
  .col-metadata {
    width: 80px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .col-provider {
    width: 200px;
  }
}

.migrate-from {
  color: $pesto-gray;
}

.migrate-to {
  margin-left: 10px;
}

.migrate-from-icon {
  &::before {
    content: '!';
    font-size: 16px;
    font-weight: 600;
    margin-right: 2px;
    color: $dark-orange;
  }
}

.migrate-to-icon {
  &::before {
    content: '!';
    font-size: 16px;
    font-weight: 600;
    color: $yellow;
    margin-right: 2px;
  }
}

.migration-in-progress {
  color: $yellow;
}