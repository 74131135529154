@import "../../../variables.scss";
@import "../../../mixins.scss";

$cell-padding-x: 16px;
$cell-padding-x-tiny: 8px;

.root {
  &--toolbar {
    margin-bottom: 50px;
  }

  &--compact {
    .row {
      height: 48px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.scroll {
  $paging-height: 52px;

  overflow-y: auto;
  height: 100%;

  &--paging {
    height: calc(100% - #{$paging-height});
  }
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.row {
  $border: solid 1px $very-light-gray;

  height: 67px;
  border-bottom: $border;

  &:first-child {
    border-top: $border;
  }
  &:hover {
    background-color: #f6f6f6;
  }

  &--disabled td {
    opacity: 0.5;

    &.col--checkbox {
      opacity: 1;
    }
  }
}

.col {
  &--checkbox {
    width: 40px;
    text-align: center;
  }
  &--manage {
    width: 95px;

    @include media-tiny {
      width: 60px;
    }
  }
}

.th {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: $cell-padding-x 0 $cell-padding-x 14px;
  text-align: left;
}

.group-header {
  font-weight: normal;
  padding-bottom: 10px;
  border-bottom: 1px solid $very-light-gray;
  border-left: 10px solid $white;
  border-right: 10px solid $white;

  &--empty {
    border-bottom: none;
  }
}

.td {
  padding: 3px $cell-padding-x;
  @include media-tiny {
    padding-right: $cell-padding-x-tiny;
  }
}

.th,
.td {
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-tiny {
    padding-left: $cell-padding-x-tiny;
  }
}

.sortable {
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:not(&--active):hover {
    .sort-icon {
      opacity: 0.5;
    }
  }

  &--active {
    .sort-icon {
      opacity: 1;
    }
  }
}

.sort-icon {
  opacity: 0;
  font-size: 18px !important;

  &--desc {
    transform: rotateZ(180deg);
  }
}