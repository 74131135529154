@import "../../../variables";

.root {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $loader-z-index;
  opacity: 0.9;
  display: none;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);

  &--open {
    display: flex;
  }
}

.wrapper {
  position: relative;
  flex: 1 1 100%;
  height: 100%;

  .root {
    position: absolute;
    z-index: $local-loader-z-index;
  }
}
