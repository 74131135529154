@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  @include size(20px);
  border-radius: 50%;
  font-size: 14px;
  color: $white;
  background-color: $pesto-gray;
  display: inline-block;
  text-align: center;
  margin-left: 8px;
  cursor: default;

  @include media-tiny {
    display: none;
  }
}
