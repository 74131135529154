.label {
  margin-bottom: 12px;
}

.local-authentication-field {
  margin-left: 30px;
}

.logout-after-field {
  margin-left: 60px;
}

.logout-after-select {
  width: 180px;
}