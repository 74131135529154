@import "../../../../variables.scss";
@import "../../../../mixins.scss";

$icon-root-width: 52px;

.root {
  position: relative;
  display: inline-block;
  border: $input-border;
  border-radius: $input-border-radius;
  height: 41px;
  overflow: hidden;
  background-color: $bluish-grey;

  &:hover {
    .icon-root {
      background-color: #476c72;
    }
  }

  @media (min-width: $screen-medium) {
    padding-right: $icon-root-width;
    width: 312px;
  }

  @include media-large {
    width: 385px;
  }
}

.input {
  border: none;
  outline: none;
  padding: 11px 33px;
  width: 100%;
  height: 100%;
  font: inherit;
  color: inherit;

  &::placeholder {
    color: #a8a8a8;
  }
}

.icon-root {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  width: $icon-root-width;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $bluish-grey;
}
