@import "../../../mixins.scss";
@import "../../../variables.scss";

.root {
  :global(.MuiToolbar-gutters) {
    padding-left: 0;
  }
  @include media-tiny {
    :global(.MuiTablePagination-selectRoot) {
      margin-left: 0;
      margin-right: 0;
    }
    :global(.MuiTablePagination-actions) {
      margin-left: 0;

      button {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }

  &--compact {
    padding-left: 10px;
    padding-right: 5px;
    border-top: 1px solid $pesto-gray;

    .displayed-rows {
      margin-left: auto;
    }

    .spacer {
      display: none;
    }
  }
}
