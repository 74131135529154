@import "../../../../variables.scss";
@import "../../../../mixins.scss";

$question-size: 25px;
$question-right: 8px;

.root {
  display: inline-block;

  @include media-tiny {
    &--tooltip {
      position: relative;

      .input {
        padding-right: $question-size + $question-right * 2;
      }
    }
  }
}

.question {
  position: absolute;
  top: calc(50% - #{$question-size} / 2);
  right: $question-right;
  border-radius: 50%;
  color: $white;
  background-color: $bluish-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  @include size($question-size);

  @include media-min-small {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 1;
}
