@import "variables";
@import "mixins";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Raleway:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

html {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: $open-sans, sans-serif;
  font-size: 14px;
  color: $black;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  list-style-type: none;
}

h1 {
  @media (min-width: $screen-small) {
    font-size: 34px;
  }
  @include media-tiny {
    font-size: 26px;
  }
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
  font-weight: normal;
}

h1,
h2,
h3,
h4 {
  font-family: $raleway;
  color: $dark-blue-grey;
}

h1 {
  font-weight: bold;
}

h2,
h3,
h4 {
  font-weight: 600;
}

ul,
ol,
li,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  transition: 0.3s all ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: $bluish-grey;
}

strong {
  font-weight: 600;
}

.error-text {
  color: $dark-orange;
  font-size: 12px;
  margin-top: 6px;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.main-scroll {
  height: 100%;
  overflow: auto;

  @media (max-width: $screen-medium - 1) {
    height: calc(100% - #{$top-nav-height});
    position: relative;
  }

  @include media-tiny {
    height: calc(100% - #{$top-nav-height-tiny});
  }
}

.main-wrapper {
  overflow: hidden;
  display: flex;
  position: relative;
}

.main-content {
  overflow: hidden;
  flex: 1;

  @media (min-width: $screen-medium) {
    padding-top: 47px;
    padding-right: 23px;
    padding-bottom: 33px;
  }
  @include media-large {
    padding-left: 70px;
  }
  @include media-medium {
    padding-left: 22px;
  }
  @include media-small {
    padding: 28px 21px 26px 18px;
  }
  @include media-tiny {
    padding-top: 23px;
    padding-right: 13px;
    padding-left: 13px;
  }
}

@media (max-width: $screen-large - 1) {
  .hidden-on-medium-screen {
    display: none !important;
  }
}

@media (max-width: $screen-medium - 1) {
  .hidden-on-small-screen {
    display: none !important;
  }
}

@media (max-width: $screen-small - 1) {
  .hidden-on-tiny-screen {
    display: none !important;
  }
}

@include media-min-max($screen-small, $screen-large - 1) {
  .hidden-on-small-to-medium-screen {
    display: none !important;
  }
}
