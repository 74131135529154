@import "../../../../variables.scss";

.content {
  display: flex;
  flex-direction: column;
}

.description {
  margin-bottom: 15px;
}

.provider-properties {
  display: flex;
  flex-direction: column;
}

.provider-fields-container {
  display: flex;
}

.provider-fields {
  width: 380px;
}

.test-button-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field-provider {
  width: 200px;
  margin-bottom: 14px;
}