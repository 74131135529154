.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.select {
  display: flex;
  flex: 1;
}

.text {
  margin-top: 20px;
}

