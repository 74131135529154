@import "../../../../variables.scss";

.root {
}

.button {
  padding: {
    left: 40px;
    right: 40px;
  }
}

.result {
  font-size: 18px;
  display: flex;
  align-items: center;

  &--success {
    color: $grassy-green;
  }

  &--fail {
    color: $dark-orange;
  }
  &__description {
    color: #a8a8a8;
    font-size: 14px;
  }
}

.icon {
  font-size: 46px;
  margin-right: 10px;
}
