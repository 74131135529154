.wrapper {
  color: #fff;
  background: #1a1e37;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  padding-top: 50px;
}

.below-header {
  border-bottom: 1px solid #5d6299;
  flex: 0 0 100%;
}

.below-header {
  p {
    margin: 0;
  }

  h1 {
    font-size: 37px;
    font-weight: 300;
    margin: 0;
  }
}

.container-style {
  max-width: 1140px;
  margin: 0 auto;
}

.justify-content-center {
  justify-content: center;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  img {
    vertical-align: middle;
    border-style: none;
  }
}

.text-center {
  text-align: center !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.step-outer {
  position: relative;
  height: 470px;
  padding-top: 40px;
}

.step-description {
  display: flex;
  align-items: center;

  span {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 10px;
    flex: 0 0 35px;
  }
}

.step-main {
  position: absolute;
  /* width: 300px; */

  &:nth-child(1) {
    width: 290px;
  }

  &:nth-child(2) {
    left: 15%;
    bottom: 0;
    width: 350px;
  }

  &:nth-child(3) {
    left: 43%;
    width: 300px;
  }

  &:nth-child(4) {
    right: 45px;
    bottom: 0;
    width: 390px;
  }

  &:nth-child(1),
  &:nth-child(3) {
    .step-description {
      margin-bottom: 15px;
    }
  }

  &:nth-child(2),
  &:nth-child(4) {
    .step-description {
      margin-top: 15px;
    }
  }
}
