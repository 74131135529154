@import "../../../variables.scss";

.root {
  font-family: inherit;
  font-size: inherit;
  border: $input-border;
  border-radius: $input-border-radius;
  padding: 10px 29px;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error & {
    border-color: $dark-orange;
  }
}
