@import "../../../../../../variables.scss";
.field {
  flex:none;
}

.message-field {
  flex: 1;
  min-height: 200px;
  max-height: 250px;
  margin-bottom: 20px;
}

.message-value {
  flex: 1;
}

.message-length {
  text-align: right;
  padding-top: 5px;
  padding-right: 10px;
  color: $gemini-gray;
}

.message-error {
  margin-top: -15px;
}