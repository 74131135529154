@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  width: 307px;
  height: 100%;
  min-height: 100vh;

  @media (min-width: $screen-medium) {
    position: relative !important;
    left: 0 !important;
  }

  @include media-medium {
    width: 98px !important;

    [class*="left-panel_content__"] {
      padding-left: 0;
    }

    [class*="left-panel_logo-link__"] {
      @include size(50px);
      margin-left: 20px;
      img {
        max-width: 150px;
      }
    }
  }

  @include media-small {
    min-height: calc(100vh - #{$top-nav-height});
  }

  @include media-tiny {
    min-height: calc(100vh - #{$top-nav-height-tiny});
  }
}


.logo-link {
  overflow: hidden;
  display: inline-block;
}

.content {
  min-height: 100%;
  padding-left: 35px;
  padding-top: 10px;
  padding-bottom: 50px;
  
  background-image: linear-gradient(to bottom, #000410, #182039);
}

@include media-large {
  .content {
    padding-top: 50px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
}

@include media-medium {
  .content {
    padding-top: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: $rich-black;
    background-image: none;
  }
}

.side-panel.left-panel {
  top: 0;
  bottom: 0;
  z-index: $left-panel-z-index;
  
  &--open {
    overflow: visible;
  }

  @include media-min-medium {
    position: relative;
    left: 0;
    background: none;
    overflow: visible;
  }

  @include media-min-max(0, $screen-medium - 1) {
    position: fixed;
    top: $top-nav-height;
  }

  @include media-tiny {
    top: $top-nav-height-tiny;
  }

  // //allow scroll only if content can't be fully visible due to small screen height
  @media (max-height: 450px) {
    overflow-y: auto;
  }
}
