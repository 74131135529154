@import "../../../../variables.scss";

table th.col-remove,
table td.col-remove {
  text-align: center;
  width: 110px;
}

.remove-icon {
  font-size: 30;
  cursor: pointer;
  color: $red;
}