@import "./variables.scss";

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin media-large {
  @media (min-width: $screen-large) {
    @content;
  }
}

@mixin media-medium {
  @media (min-width: $screen-medium) and (max-width: $screen-large - 1) {
    @content;
  }
}

@mixin media-small {
  @media (min-width: $screen-small) and (max-width: $screen-medium - 1) {
    @content;
  }
}

@mixin media-small-or-less {
  @media (max-width: $screen-medium - 1) {
    @content;
  }
}

@mixin media-medium-or-less {
  @media (max-width: $screen-large - 1) {
    @content;
  }
}

@mixin media-tiny {
  @media (max-width: $screen-small - 1) {
    @content;
  }
}

@mixin media-min-max($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin media-max-small {
  @media (max-width: $screen-small) {
    @content;
  }
}

@mixin media-min-small {
  @media (min-width: $screen-small) {
    @content;
  }
}

@mixin media-min-medium {
  @media (min-width: $screen-medium) {
    @content;
  }
}
