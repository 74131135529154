@import "../../../../variables.scss";

.root {
  margin-bottom: 35px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.title {
  flex: 0 0 auto;
}

.divider {
  flex: 1 1 100%;
  border-top: 1px solid $very-light-gray;
  margin-left: 11px;
}

.description {
  margin-top: -15px;
  margin-bottom: 25px;
  font-size: 16px;
}