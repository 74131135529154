@import "../../../../../../variables.scss";
@import "../../../../../../mixins.scss";

$max-form-width: 700px;
$footer-height: 145px;

.root {
  height: 100%;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-columns {
  display: flex;
  flex: 1;

  @include media-max-small {
    flex-direction: column;
    position: relative;
    align-items: center;
  }
}

.email-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: $very-light-gray;
  padding: 32px 60px 32px 60px;

  @include media-max-small {
    width: 100%;
  }
}

.email-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: $max-form-width;
  width: 100%;
}

.email-form-header {
  margin-left: -20px;
  margin-bottom: 35px;
}

.email-settings-button {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.files-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include media-max-small {
    margin-bottom: $footer-height;
    max-width: $max-form-width;
    width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: $very-light-gray;

  @include media-max-small {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $footer-height;
    justify-content: center;
    align-items: center;
  }
}

.footer-content {
  padding-right: 10px;

  @include media-max-small {
    max-width: $max-form-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.footer-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button:first-child {
    margin-right: 19px;
  }
}

.files-over-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bluish-grey;
  opacity: 0.5;

  &::after {
    content: 'Drop Files Here';
    font-size: 64px;
  }
}