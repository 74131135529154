@import "../../../../../../variables.scss";

.image-icon {
  color: #1d68f1;
  font-size: 32px !important;
  margin: -4px;
}

.file-icon {
  width: 24px;
  height: 24px;
  background-color: $dark-gun-powder;
  color: $white;
  font-size: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 3px;
  border-radius: 3px;

  &--pdf {
    background: #e13d34;
  }

  &--doc {
    background: #307cf1;
  }

  &--xls {
    background: #0f9d58;
  }

  &--ppt {
    background: #d24726;
  }

  &--txt {
    background: #5eb533;
  }

  &--mp3,
  &--wma,
  &--m4a,
  &--fla {
    background: #8e44ad;
  }

  &--mp4,
  &--wmv,
  &--mov,
  &--avi,
  &--mkv {
    background: #7a3ce7;
  }
}