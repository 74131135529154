@import "../../../variables.scss";

$transition-duration: 0.3s;

.root {
  @mixin side($side) {
    &--#{$side} {
      .content {
        position: absolute;
        #{$side}: -100%;
      }
      &.root--open {
        .content {
          #{$side}: 0;
        }
      }
    }
  }

  position: absolute;
  top: 0;
  right: 100%;
  bottom: 100%;
  left: 0;
  background-color: rgba(#000000, 0.7);
  overflow: hidden;
  transition-timing-function: linear;
  transition-property: top, left, right, bottom;

  &--open {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:not(&--open) {
    transition-delay: $transition-duration;
  }

  @include side(top);
  @include side(right);
  @include side(bottom);
  @include side(left);
}

.content {
  position: absolute;
  transition-duration: $transition-duration;
  transition-timing-function: linear;
  transition-property: top, left, right, bottom;
  outline: none;
}
