@import "../../../../variables.scss";

.root {
  flex: 1;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $gemini-gray;
  border-radius: $input-border-radius;
  padding: 9px 15px;
  width: 100%;
  background-color: $white;
  flex: 1;
}

.input {
  flex: 1;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  font-size: 16px;
  &::placeholder {
    color: $pesto-gray;
  }

  &:disabled {
    color: $pesto-gray;
    background-color: $white;
  }
}

.chip {
  height: 26px;
  border-radius: 13px;
  background-color: $pesto-gray;
  display: flex;
  align-items: center;
  color: $white;
  padding: 0 8px;
  margin-right: 5px;
}

.delete-icon  {
  cursor: pointer;
  color: black;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: $white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
}

.chip-text {
  padding-bottom: 2px;
}

.popup {
  max-height: 40%;
  overflow: auto;
}

.active-contact {
  color: $white;
  background-color: $bluish-grey;
}