@import "../../../variables.scss";

.table {
  border:1px solid $gemini-gray;
  border-radius: 20px;
  border-spacing: 0;
  overflow: hidden;
  margin-bottom: 50px;
  width: 100%;

  td, th {
    border-left: solid $gemini-gray 1px;
    border-top: solid $gemini-gray 1px;
    padding: 10px 16px;
    text-align: left;
  }

  th {
      background-color: $gallery;
      border-top: none;
      font-weight: 600;
  }

  td:first-child, th:first-child {
      border-left: none;
  }
}