@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  display: inline-flex;
  align-items: center;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  cursor: pointer;
  background: none;
  color: inherit;

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  &--inline {
    font-size: inherit;
    font-weight: inherit;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  &--left-margin {
    margin-left: 20px;
  }
}

.primary,
.secondary,
.extreme {
  padding: 8px 25px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 22px;
  white-space: nowrap;

  &:disabled,
  &[disabled] {
    color: $white;
    background-color: $pesto-gray;
    border-color: transparent;
  }
}

.primary {
  color: $white;
  background-color: $bluish-grey;

  &:hover {
    background-color: #476c72;
  }
}

.secondary {
  color: $bluish-grey;
  background-color: $white;
  border: solid 1px $bluish-grey;

  &:not(.selected):hover {
    background-color: #e9eff0;
  }

  &.selected {
    color: $white;
    background-color: $gemini-gray;
    border-color: transparent;
  }
}

.extreme {
  color: $white;
  background-color: $dark-orange;

  &:hover {
    background-color: #8d2f03;
  }
}

.link {
  color: $bluish-grey;
  text-decoration: underline;

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }
}

.inherit {
  color: inherit;
  font-size: inherit;
}

.responsive {
  @include media-tiny {
    padding: 3px 13px;
  }
}
