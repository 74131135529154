@import "../../../mixins.scss";

.root {
  display: inline-block;
}

.button {
  margin-right: 5px;
  padding: 9px 15px;

  @include media-tiny {
    margin-right: 3px;
  }
}
