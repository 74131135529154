@import "../../../mixins.scss";

.root {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 100%;

  button {
    margin-left: 10px;
  }

  @include media-tiny {
    justify-content: space-between;
  }
}
