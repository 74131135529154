@import "../../mixins.scss";

.container {
  max-width: 100%;
  min-width: 960px;
  margin: 0 auto;
  overflow: auto;
}

.logo {
  width: 262px;
  height: 82px;

  &--small {
    width: 151px;
    height: 48px;
  }
}

.header-svg svg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vw;
}

.return-link {
  margin: 20px;
}

.login-button {
  border-radius: 25px;
  width: 170px;
  height: 50px;
  justify-content: center;
  text-transform: capitalize;
  font-weight: normal;
  font-size: 24px;
  margin-top: 70px;
}

.data-wrapper {
  padding: 10px;
  background-image: linear-gradient(to top, #000410, #171f38);
  text-align: center;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-min-small {
    padding: 20px;
  }

  h1 {
    color: inherit;
    letter-spacing: 0.57px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 7px;
  }

  p {
    color: #e3e3e3;
    opacity: 0.79;
    font-size: 20px;
    font-weight: normal;
  }
}

.security-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px 0px 70px;
  display: flex;
  align-items: center;
}

.security-desc {
  h3 {
    font-size: 30px;
    font-weight: normal;
    position: relative;
    margin-bottom: 22px;
    &::after {
      content: "";
      width: 97px;
      height: 6px;
      background: #e7e7e7;
      position: absolute;
      bottom: -11px;
      left: 0px;
    }
  }

  h4 {
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    font-weight: normal;
  }
}

.bulb {
  margin-right: 45px;
}
