@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  display: flex;
  justify-content: space-between;

  .tooltip-question {
    margin-left: 0;

    @include media-tiny {
      display: inline-block;
    }
  }

  @include media-min-max(0, $screen-medium) {
    flex-wrap: wrap-reverse;
  }
}

.border {
  border: 1px solid $very-light-gray;
  background-color: #f8f8f8;
  height: 500px;
  display: grid;
  grid-template: auto minmax(0, 1fr) / 100%;
}

.col {
  margin-bottom: 33px;

  @include media-min-medium {
    &:nth-child(1) {
      width: 63%;
    }
    &:nth-child(2) {
      width: 35%;
    }
  }
  @include media-tiny {
    width: 99% !important;
    margin-bottom: 30px;
  }
}

.list-title {
  font-size: 18px;
  font-weight: 600;
}

.filters {
  padding: 24px 10px;
  background-color: $alto;

  @include media-tiny {
    padding: 10px;
  }

  &__bar {
    display: flex;
    flex-wrap: nowrap;
    margin: 9px 0 0 0;
  }
  &__search {
    flex: 1;
    margin-right: 14px;
  }
  &__profile {
    flex: 0 0 auto;
    width: 142px;
    background-color: $white;
  }
}

.added-title {
  font-size: 18px;
  font-weight: 600;
  margin: 24px 29px 10px 24px;
}

.footer {
  color: $gemini-gray;
  margin-top: 22px;
}

.col {
  &--radio {
    width: 40px;
    text-overflow: clip;
  }
  &--size {
    width: 100px;
  }
  &--user-group {
    white-space: nowrap;
  }
}

.row {
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.exceeded-users {
  &__info {
    color: $dark-orange;
    border-left: 6px solid currentColor;
    padding-left: 10px;
    font-size: 16px;
    margin-bottom: 35px;
  }
  &__placeholder {
    margin-left: 54px;
  }
}
