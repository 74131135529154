@import "../../../variables.scss";

.root {
  padding: 0;
  border: none;
  background: none;
  text-decoration: underline;
  font-size: inherit;
  font-family: inherit;
  color: $bluish-grey;
  cursor: pointer;

  &:focus {
    outline: none
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

.small {
  font-size: 12px;
}

.medium {
  font-size: 14px;
}

.large {
  font-size: 16px;
}

