@import "../../../../variables.scss";

.root {
  font: inherit;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .input {
    opacity: 0;
    width: 0;
    height: 9px;
    margin: 0;
  }
}


.icon {
  $size: 18px;

  display: inline-flex;
  width: $size;
  height: $size;
  border: solid 1px $gemini-gray;
  color: $white;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;

  svg {
    font-size: $size;
  }
}

.checked,
.indeterminate {
  background-color: #707070;
}

.label {
  text-align: left;
  margin-left: 5px;
  color: $black;
}
