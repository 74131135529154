@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  display: flex;
  align-items: center;

  @media (min-width: $screen-small) {
    margin-bottom: 24px;
  }
  @include media-tiny {
    margin-bottom: 19px;
  }
}

.title {
  text-transform: capitalize;

  @media (min-width: $screen-small) {
    margin-right: 27px;
  }
  @include media-tiny {
    margin-right: 14px;
  }
}

.content {
  display: flex;
}
