@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  height: 40px;
  border: solid 1px $gemini-gray;
  background-color: $white;
  color: $black;
  font-size: 16px;
  min-width: 100px;
  outline: none;

  &--disabled {
    pointer-events: none;
    color: $gemini-gray;
  }
  &--fit-parent {
    width: 100%;
  }
}

fieldset.root {
  border-radius: 25px;
  display: inline-block;
  padding: 0;
  vertical-align: bottom;

  &--label {
    height: 50px;
    margin-top: -10px;
    padding-bottom: 5px;
  }
}

.legend {
  font-size: 12px;
  margin-left: 10px;
}

select.root {
  font-family: inherit;
  padding-left: 5px;
}

.content {
  @include size(100%);
  padding: {
    left: 20px;
    right: 17px;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.selected {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  white-space: nowrap;

  &--placeholder {
    font-size: 14px;
    color: $gemini-gray;
  }
}

.icon {
  $border-transparent: 6.5px solid transparent;
  flex: 0;
  @include size(0);
  border-left: $border-transparent;
  border-right: $border-transparent;
  border-top: 8px solid $gemini-gray;
}

.popup {
  max-height: 40%;
  overflow: auto;
}
