@import "../../../../variables.scss";

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  &--height-to-content {
    flex: none;
  }
}

.content {
  flex: 1;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.error-message {
  color: $dark-orange;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 10px;
  max-width: 300px;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
}

.next-button {
  margin-left: 10px;
}
